import { Table } from "antd";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react";

function MonthlyTable({ gamedata, dropValue }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectMonthDrop, setSelectMonthDrop] = useState("");

  const currentYear = moment().tz("Asia/Kolkata").year();
  const currentMonth = moment().tz("Asia/Kolkata").format("MMMM"); // Get the full month name for current month
  const defaultMonth = moment().tz("Asia/Kolkata").month() + 1; // January is 0, so add 1
  const initialMonth = dropValue?.month || defaultMonth;
  const initialYear = dropValue?.year || currentYear;

  useEffect(() => {
    // Fetch data for the initial or selected date
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        month: initialMonth,
        year: initialYear,
        gameName: dropValue?.gameName || "",
        result: dropValue?.result || "",
        days: moment(`${initialYear}-${initialMonth}-01`).daysInMonth(),
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
      })
      .catch((error) => console.error(error));
  }, [dropValue, initialMonth, initialYear]);

  useEffect(() => {
    if (dropValue) {
      const monthDrop = dropValue.month
        ? moment(`${dropValue.year}-${dropValue.month}-01`).format("MMMM")
        : currentMonth;
      setSelectMonthDrop(monthDrop);
    } else {
      setSelectMonthDrop(currentMonth);
    }
  }, [dropValue, currentMonth]);

  useEffect(() => {
    if (data.length > 0) {
      const array = Object.keys(data[0]).map((key) => ({
        title: key === "day" ? selectMonthDrop : key,
        dataIndex: key,
        key,
      }));
      setColumns(array);
    } else {
      setColumns([]);
    }
  }, [data, selectMonthDrop]);

  return (
     
    <div id="table-section" className="monthYrTbl pt-3">
      <h3 className="text-center  p-2" style={{ display: "block" }}>
        {dropValue?.gameName} MONTHLY RECORD CHART{" "}
        {dropValue?.year || currentYear}
      </h3>
      <div className="tableStyle table-responsive">
        <Table
          dataSource={data.length > 0 ? data : []}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default MonthlyTable;
