import "./App.css";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import trackVisitor from "./utilities/tracker";
import GameResult from "./js/GameResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";

function App() {
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm:ss")); // State to store the current time including seconds
  const [startTime, setStartTime] = useState(moment()); // Track the time when the app started
  const location = useLocation();
  const isDisclaimer = location.pathname.includes("/disclaimer");
  const isAbout = location.pathname.includes("/about-us");
  const isPrivacy = location.pathname.includes("/privacy-policy");

  const todayDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    // Update current time every second
    const timeInterval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm:ss")); // Update time with seconds
    }, 1000); // Updates every second

    return () => clearInterval(timeInterval); // Clean up interval on component unmount
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);

  const pageScroll = () => {
    const tableSection = document.getElementById("table-section");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Calculate the running time since the app started
  const runningTime = moment.duration(moment().diff(startTime)).humanize();

  return (
    <div className="app">
      {/* SEO setup */}
      <Helmet>
        <title></title>
        <meta name="description" content="sattakingy, satta kingy, sattaking" />
        <meta
          name="Keywords"
          content="sattakingy, satta kingy, sattaking, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king"
        />
        <link rel="canonical" href="https://sattakingy.co/" />
      </Helmet>

      {/* Navigation Menu */}
      <div className="col-md-12 menu text-center">
        <div className="row">
          <div className="col-md-4 col-sm-12 nav-link">
            <a className="text-black" href="/">
              HOME
            </a>
          </div>
          <div className="col-md-4 col-sm-12 nav-link">
            <a
              onClick={(e) => {
                e.preventDefault();
                pageScroll();
              }}
              href="chart"
              className="text-black"
            >
              CHART
            </a>
          </div>
          <div className="col-md-4 col-sm-12 nav-link">
            <a
              onClick={(e) => {
                e.preventDefault();
                pageScroll();
              }}
              href="SATTA KING 2024"
              className="text-black"
            >
              SATTA KING 2024
            </a>
          </div>
        </div>
      </div>

      {/* Main Content */}
      {!(isAbout || isDisclaimer || isPrivacy) && (
        <>
          <div className="text-center highlight p-1 bg-black">
            <h3 className="fw-bold text-danger">SATTA KING</h3>
          </div>

          <div className="text-center mt-4">
            <div className="text-center p-1 banner fw-bolder text-white">
              <h2>SATTAKINGY.CO BEST SITE SATTA RESULT</h2>
              <p>SATTAKINGY.CO</p>
            </div>

            <div className="text-center">
              <p className="text-danger fw-bold fs-3 mb-0">{currentDate}</p>
              <p className="text-primary fw-bold fs-5 mb-0">
                Satta King Live Result Today
              </p>

              <div className="current-time-container text-center">
                <p className="text-primary fw-bold fs-4 mb-3">
                  Current Time: {currentTime}
                </p>
              </div>

              {datagame?.map((todayData, index) => (
                <div key={index}>
                  <p className="game-name mb-0">{todayData?.gameName}</p>
                  <p className="game-result lh-1 blinking-text">
                    {todayData?.result || "wait"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {/* Advertisement and Game Results */}
      <AdvertisementComponent type="odd" />
      <GameResult dayGameData={data} />
    </div>
  );
}

export default App;
