import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Import the phone icon

const AdvertisementComponent = ({ type }) => {
  // Dummy data
  const dummyData = [
    {
      name: 'Sohan vyas',
      description:
        '101% GURANTEE PAYMENT KI 💵💸 पेमेंट रेट 💸💵जोड़ी रेट 👉10 के 960 हर्फ़ रेट 👉100 के 960🌀लखनऊ गोल्ड.....12:05 PM🌀दिल्ली बाजार.....3:00 PM🌀श्री गणेश...........4:30 PM🌀फरिदाबाद..........5:55 PM🌀गाजियाबाद........8:50 PM🌀गली................11:00 PM🌀दिसावर..............4:40AM💵💸 पेमेंट रेट 💸💵जोड़ी रेट 👉10 के 960 95हर्फ़ रेट 👉100 के 960 PMNOTE: सुपर फास्ट पेमेंट का वादा मेरे पास गेम पास होने के 10 मिनट बाद आपकी पेमेंट तुरंत दी जाएगींदर अंदर होगा आकाश ठाकुर खाईवाल भैया जी',
      mobile: '9668665275',
      post: 'HEAD OF COMPANY',
    },
    {
      name: 'Chandrashekhar',
      description:
        'SATTA KING KA WADA 1001% PAYMENT KI GARANTI जोडी रेट 10 के 95 हरूप रेट 100 के 950 PM Game Lene Ka Last Time Haridwar ➪ 11:55am Ghaziabad Afternoon ➪ 02:00pm Ghaziabad Din ➪ 04:30pmलखनऊ गोल्ड - 12:00 PM फरीदाबाद-06:00 PM गाज़ियाबाद :- 08:45 P गली :- 11:30 PM दिसावर :- 04:50 AM भोपाल Morning - 4:30 AM PAYMENT GAME PASS KE 30 MIN BAAD MILEGA ',
      mobile: '8979657322',
      post: 'राजू भाई खाईवाल',
    },
    {
      name: 'Ajay jat',
      description:
        'अपना सट्टा बाजार साइट पर लगवाने के लिए साइट पर बोर्ड लगवाने के लिए मैसेज करें गेम रिजल्ट डलवाने के लिए यहाँ संपर्क करें गेम मांगने वाले यहाँ माँ ना चुदायें',
      mobile: '09661083137',
      post: 'Vedant Mishra OWNER',
    },
  ];

  const [oddAdd, setOddAdd] = useState([]);
  const [evenAdd, setEvenAdd] = useState([]);
  const [randomAdd, setRandomAdd] = useState([]);

  useEffect(() => {
    // Use the dummy data instead of fetching it from an API
    const odd = [];
    const even = [];
    const random = [];

    // Divide data into three arrays
    dummyData.forEach((item, index) => {
      if (index % 3 === 0) {
        odd.push(item); // Every 3rd item goes into odd
      } else if (index % 3 === 1) {
        even.push(item); // Every 3rd + 1 item goes into even
      } else {
        random.push(item); // Every 3rd + 2 item goes into random
      }
    });

    // Update the state with the categorized data
    setOddAdd(odd);
    setEvenAdd(even);
    setRandomAdd(random);
  }, []);

  // generate whatsapp massanger
  const generateWhatsAppLink = (mobile, name) => {
    const message = `Hello ${name}, I am interested in your advertisement.`;
    return `https://api.whatsapp.com/send?phone=${mobile}&text=${encodeURIComponent(
      message
    )}`;
  };

  // generate call dialer
  const generateCallLink = (mobile) => {
    return `tel:${mobile}`; // The 'tel:' protocol will open the phone dialer with the provided number
  };

  return (
    <div className="advertisementSection">
      {type === 'odd' && (
        <div className="row">
          {oddAdd && oddAdd.length > 0 ? (
            oddAdd.map((odd, index) => (
              <div key={index} className="col-md-12 ad-section">
                <div className="col-lg-12">
                  <h3>name: {odd?.name}</h3>
                  <h6
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: odd?.description,
                    }}
                  />
                  <h4>Mobile: {odd?.mobile}</h4>
                  <h4>post: {odd?.post}</h4>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        {/* WhatsApp Button */}
                        <a
                          href={generateWhatsAppLink(odd.mobile, odd.name)}
                          target="_blank"
                          className="form-control m-2 btn ad-btn col-md-6 col-sm-12"
                        >
                          <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp Now
                        </a>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        {/* Call Button */}
                        <a
                          href={generateCallLink(odd.mobile)}
                          className="form-control m-2 btn ad-btn col-md-6 col-sm-12"
                        >
                          <FontAwesomeIcon icon={faPhone} /> Call Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}

      {type === 'even' && (
        <div className="row">
          {evenAdd && evenAdd.length > 0 ? (
            evenAdd.map((even, index) => (
              <div key={index} className="col-am-12 ad-section">
                <div className="col-lg-12">
                  <h3>name: {even?.name}</h3>
                  <h6
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: even?.description,
                    }}
                  />
                  <h4>Mobile: {even?.mobile}</h4>
                  <h4>post: {even?.post}</h4>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        {/* WhatsApp Button */}
                        <a
                          href={generateWhatsAppLink(even.mobile, even.name)}
                          target="_blank"
                          className="form-control m-2 btn ad-btn col-md-6 col-sm-12"
                        >
                          <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp Now
                        </a>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        {/* Call Button */}
                        <a
                          href={generateCallLink(even.mobile)}
                          className="form-control m-2 btn ad-btn col-md-6 col-sm-12"
                        >
                          <FontAwesomeIcon icon={faPhone} /> Call Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}

      {type === 'random' && (
        <div className="row">
          {randomAdd && randomAdd.length > 0 ? (
            randomAdd.map((random, index) => (
              <div key={index} className="col-am-12 ad-section">
                <div className="col-lg-12">
                  <h3>name: {random?.name}</h3>
                  <h6
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: random?.description,
                    }}
                  />
                  <h4>Mobile: {random?.mobile}</h4>
                  <h4>post: {random?.post}</h4>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        {/* WhatsApp Button */}
                        <a
                          href={generateWhatsAppLink(
                            random.mobile,
                            random.name
                          )}
                          target="_blank"
                          className="form-control m-2 btn ad-btn col-md-6 col-sm-12"
                        >
                          <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp Now
                        </a>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        {/* Call Button */}
                        <a
                          href={generateCallLink(random.mobile)}
                          className="form-control m-2 btn ad-btn col-md-6 col-sm-12"
                        >
                          <FontAwesomeIcon icon={faPhone} /> Call Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AdvertisementComponent;
